<template>
  <div class="bg">
    <div class="top">
      <img src="../../../assets/img/inquiry_10.png" alt="" />
      <div>
        <h3>王涛 主任医师</h3>
        <p>中国人民解放军总医院301医院</p>
      </div>
    </div>
    <van-tabs v-model="active" animated color="#1DAEFC" line-height="6" line-width="60">
      <van-tab title="擅长领域">
        <div v-html="html" class="myhtml"></div>
      </van-tab>
      <van-tab title="接诊时间" v-if="!privatedoctor">
        <div class="tiembox">
          <div
            class="list"
            :class="{ on: on == item }"
            v-for="item in 10"
            :key="item"
            @click="pitchon(item)"
          >
            <span></span>
            <p>10月31日 8:00-12:00 <em>6人预约</em></p>
          </div>
        </div>
        <div class="btnbox">
          <van-button round type="info" size="large" @click="gonav('appointment')"
            >申请问诊</van-button
          >
        </div>
      </van-tab>
      <van-tab title="服务范围" v-if="privatedoctor">
        <!-- <div class="privatedoctor">
          <div class="privatedoctor_1">服务费用: <span>300元/月</span></div>
          <div class="privatedoctor_2">
            <p>1. 30天，独家健康指导</p>
            <p>2. 健康咨询，不限次数，不限时间</p>
            <p>3.患病驻院，实时指导</p>
            <p>4.xxxxxx的权益</p>
          </div>
        </div>
        <div class="btnbox">
          <van-button round type="info" size="large" @click="gonav('privatedoctororde')"  color="rgba(29,174,252,1)"
            >购买私人医生服务</van-button
          >
        </div> -->
        <div class="time_list">
          <ul>
            <li class="on">
              <i></i>
              <b></b>
              <p>10月31日  8:00-12:00 <span>6人预约</span></p>
            </li>
            <li>
              <i></i>
              <b></b>
              <p>10月31日  8:00-12:00 <span>6人预约</span></p>
            </li>
            <li>
              <i></i>
              <b></b>
              <p>10月31日  8:00-12:00 <span>6人预约</span></p>
            </li>
            <li>
              <i></i>
              <b></b>
              <p>10月31日  8:00-12:00 <span>6人预约</span></p>
            </li>
            <li>
              <i></i>
              <b></b>
              <p>10月31日  8:00-12:00 <span>6人预约</span></p>
            </li>
          </ul>

          <div class="button">
            <button>申请问诊</button>
          </div>
        </div>
      </van-tab>
      <van-tab title="职业经历">
        <div class="infolist">
          <ul>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
            <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
          </ul>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      html: "<h1>asdasdasdasd</h1>",
      on: "",
      privatedoctor: true,
    };
  },
  methods: {
    pitchon(item) {
      this.on = item;
    },
    // 预约or支付
    gonav(url){
      this.$router.push({ name: url });

    },
    // appointment() {
    //   this.$router.push({ name: "appointment" });
    // },
  },
};
</script>
<style lang="less" scoped>
.bg {
  .privatedoctor {
    padding: 24px;
    background: #fff;
    .privatedoctor_1 {
      margin-bottom: 24px;
      span {
        color: rgba(29, 174, 252, 1);
        margin-left: 24px;
      }
    }
    .privatedoctor_2 {
      width: 343px;
      height: 106px;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      padding: 16px;
    }
  }
  .tiembox {
    padding: 24px;
    background: #fff;
    margin-bottom: 20px;
    .list {
      border-left: 4px solid rgba(226, 245, 255, 1);
      position: relative;
      padding-bottom: 24px;
      &:nth-last-child(1) {
        padding-bottom: 0;
      }
      &.on {
        color: rgba(29, 174, 252, 1);
        span {
          background: rgba(29, 174, 252, 1);
        }
        p {
          border: 1px solid rgba(29, 174, 252, 1);
        }
      }
      span {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: rgba(194, 194, 194, 1);
        left: -10px;
        top: 0;
      }
      p {
        line-height: 44px;
        width: 256px;
        // height: 44px;
        border: 1px solid rgba(194, 194, 194, 1);
        border-radius: 6px;
        margin-left: 36px;
        text-align: left;
        padding-left: 10px;
        em {
          font-style: normal;
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
  .btnbox {
    padding: 16px;
    background: #fff;
  }
  .myhtml {
    background-color: #fff;
    text-align: left;
    padding: 16px;
  }
  .infolist {
    padding: 16px;
    background: #fff;

    h3 {
      text-align: left;
      margin-bottom: 16px;
    }
    ul {
      li {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        // padding: 15px 0;
        line-height: 49px;
        color: #2d2d2d;
        font-size: 14px;
      }
    }
  }
  .top {
    height: 113px;
    padding: 16px;
    background: #1daefc;
    display: flex;
    text-align: left;
    color: #fff;
    font-size: 12px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    img {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  .time_list { width: 100%; background: #fff; margin-top: 8px; padding: 10px 0 0 0; height: calc(100vh - 165px); position: relative;
    ul {
      height: calc(100vh - 290px); overflow: auto; border-bottom: 8px solid #f5f5f5;
      li {
        width: 100%; height: auto; position: relative;
        p { width: 256px; height: 44px; border: 1px solid #C2C2C2; line-height: 42px; font-size: 14px; color: #444; margin: 12px auto; padding: 0 10px; border-radius: 7px;
          span { float: right;} 
        }
        b { width: 16px; height: 16px; border-radius: 8px; background: #C2C2C2; display: block; position: absolute; left: 20px; top:0 } 
        i {width: 9px; height: 68px; background: #E2F5FF; position: absolute; left: 24px; top:0 }
      }
      li.on {
        p {border-color: #1DAEFC; color: #1DAEFC;}
        b { background: #1DAEFC;} 
      }
    }
    .button{
      display: flex; justify-content: center; width: 100%; height: 124px; align-items: center;
      button {
        width: 343px;
        height: 44px;
        opacity: 1;
        background: #1daefc;
        border-radius: 10px;
        margin: 0 auto;
        color: #fff;
        border: none;
      } 
    }
    
  } 
}
</style>
